import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Image } from "../Core"
import ImageCaption from "./ImageCaption"

function FacewallImage(props) {
  let url = props.url
  if (url.charAt(0) !== "/") url = "/" + url

  return (
    <>
      <div className="column is-6 fixed-facewall-item has-text-centered">
        <div className={props.className}>
          <Link title="View the full testimonial" to={url.toLowerCase()}>
            <Image useAR publicId={props.imageUrl} responsiveUseBreakpoints />
          </Link>
          {(props.reviewerName || props.procedure || props.monthYear) && (
            <Link
              title="View the full testimonial"
              className="image-caption-link"
              to={url.toLowerCase()}>
              <ImageCaption
                reviewerName={props.reviewerName}
                language={props.language}
                procedure={props.procedure}
                monthYear={props.monthYear}
                hideProcedureNames={props.hideProcedureNames}
              />
            </Link>
          )}
        </div>
      </div>
    </>
  )
}

FacewallImage.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  procedure: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  reviewerName: PropTypes.string,
  language: PropTypes.string,
  monthYear: PropTypes.object
}

export default FacewallImage
